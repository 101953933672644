import axios from "axios";

// 前置路径，防止业务需求变更
let baseUrl = '';

// JSON格式的POST请求
export const postRequest = (url, params) => {
	return axios({
		method: 'POST',
		url: `${baseUrl}${url}`,
		data: params
	})
}

// JSON格式的GET请求
export const getRequest = (url, params) => {
	return axios({
		method: 'GET',
		url: `${baseUrl}${url}`,
		data: params
	})
}

// JSON格式的PUT请求
export const putRequest = (url, params) => {
	return axios({
		method: 'PUT',
		url: `${baseUrl}${url}`,
		data: params
	})
}

// JSON格式的DELETE请求
export const deleteRequest = (url, params) => {
	return axios({
		method: 'DELETE',
		url: `${baseUrl}${url}`,
		data: params
	})
}