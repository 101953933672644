<template>
  <div class="common-layout">
    <el-container>
      <el-header class="header-container">
        <div class="header-title">
          Aitxin
        </div>
        <div class="nav-container">
        </div>
      </el-header>
      <el-main class="main">
        <div class="search-container">
          <el-input v-model="searchContent" placeholder="键入以搜索..." @keypress.enter="searchPress"/>
        </div>
        <el-card class="box-card" v-for="website in webSites" :key="website">
          <el-link v-for="item in website" :href="item.src" :key="item.src" target="_blank">
            {{item.name}}
          </el-link>
        </el-card>
      </el-main>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      searchContent: '',
      webSites: null,
    };
  },
  methods: {
    searchPress() {
      window.open("https://cn.bing.com/search?q=" + this.searchContent);
    },
    searchWebSitesData () {
      this.$getRequest("/data/links.json", "").then(response => {
        if (response) {
          console.log(response);
          this.webSites = response.data;
        }
      })
    }
  },
  mounted() {
    this.searchWebSitesData();
  }
};
</script>

<style>
.header-container {
  height: 80px;
  background-color: aliceblue;
  display: flex;
  justify-content: space-between;
}
.header-title {
  font-size: 3rem;
  font-weight: bolder;
  height: 100%;
  align-self: center;
}
.search-container {
  margin-bottom: 12px;
}
.main {
  width: 1024px;
  margin: 0 auto;
}
.el-link {
  width: 15%;
}
.el-link span {
  padding: 8px 0;
  font-size: 1rem;
}
.box-card {
  margin-bottom: 12px;
}
</style>