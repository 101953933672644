import {createApp} from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import ElementPlus from "element-plus"
import 'element-plus/dist/index.css'
import '@/assets/css/common.css'

let vm = createApp(App);

vm.use(store)
		.use(router)
		.use(ElementPlus);

vm.mount("#app");

import * as ElementPlusIconsVue from '@element-plus/icons-vue'

Object.keys(ElementPlusIconsVue).forEach(key => {
	vm.component(ElementPlusIconsVue[key].name, ElementPlusIconsVue[key]);
})

import {
	postRequest,
	getRequest,
	putRequest,
	deleteRequest
} from "@/utils/api";

vm.config.globalProperties.$postRequest = postRequest;
vm.config.globalProperties.$getRequest = getRequest;
vm.config.globalProperties.$putRequest = putRequest;
vm.config.globalProperties.$deleteRequest = deleteRequest;
